import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Projects from "../../api/project";
import { Link } from "react-router-dom";
import SectionTitle2 from "../SectionTitle2/SectionTitle2";

import { atom, useRecoilState } from "recoil";

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const ProjectSection = (props) => {
  const langState = atom({
    key: "langState", // unique ID (with respect to other atoms/selectors)
    default: navigator.language === "ko" ? "ko" : "en", // default value (aka initial value)
  });
  const [lang, setLang] = useRecoilState(langState);

  return (
    <section className={`wpo-project-section section-padding ${props.pClass}`}>
      <div className="container">
        {lang === "ko" && (
          <SectionTitle2
            subTitle={"OUR PRODUCTS"}
            Title={"크리에이티브의 방식을 바꾸다"}
          />
        )}
        {lang === "en" && (
          <SectionTitle2
            subTitle={"OUR PRODUCTS"}
            Title={"Change one's creative ways"}
          />
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col col-xs-12 sortable-project">
            <div className="wpo-project-container project-slider owl-carousel">
              <Slider {...settings}>
                {Projects.slice(0, 4).map((project, prj) => (
                  <div className="grid" key={prj}>
                    <div className="wpo-project-item">
                      <img
                        src={project.pImg}
                        alt="project"
                        className="img img-responsive"
                      />
                      {lang === "ko" && (
                        <div className="wpo-project-text">
                          <h3>
                            {/* <Link
                            onClick={ClickHandler}
                            to={`/project-single/${project.Id}`}
                          >
                            {project.title}
                          </Link> */}
                            {project.title}
                          </h3>
                          <span>{project.subTitle}</span>
                        </div>
                      )}
                      {lang === "en" && (
                        <div className="wpo-project-text">
                          <h3>
                            {/* <Link
                            onClick={ClickHandler}
                            to={`/project-single/${project.Id}`}
                          >
                            {project.title}
                          </Link> */}
                            {project.title2}
                          </h3>
                          <span>{project.subTitle2}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
