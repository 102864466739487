import React from "react";
import shape from "../../images/ct-shape.png";

import { atom, useRecoilState } from "recoil";

const ContactInfo = (props) => {
  const langState = atom({
    key: "langState", // unique ID (with respect to other atoms/selectors)
    default: navigator.language === "ko" ? "ko" : "en", // default value (aka initial value)
  });
  const [lang, setLang] = useRecoilState(langState);

  return (
    <section className="contact-info-section section-padding">
      <div className="container">
        <div className="office-info">
          <div className="row justify-content-center">
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="office-info-item">
                <div className="office-info-icon">
                  <div className="icon">
                    <i className="fi flaticon-place"></i>
                  </div>
                </div>
                <div className="office-info-text">
                  {lang === "ko" && (
                    <p>
                      서울 서초구
                      <br />
                      반포대로 38 와라빌딩 5층
                    </p>
                  )}
                  {lang === "en" && (
                    <p>
                      5th floor, Wara Building
                      <br />
                      38 Banpo-daero, Seocho-gu, Seoul
                    </p>
                  )}
                  <span>KOREA, OFFICE ADDRESS</span>
                </div>
              </div>
            </div>
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="office-info-item">
                <div className="office-info-icon">
                  <div className="icon">
                    <i className="fi flaticon-email"></i>
                  </div>
                </div>
                <div className="office-info-text">
                  <p>
                    support@musiaplugin.com
                    <br />
                    &nbsp;
                  </p>
                  <span>EMAIL US</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ct-shape">
        <img src={shape} alt="shape" />
      </div>
    </section>
  );
};

export default ContactInfo;
