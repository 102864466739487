import simg from "../images/slide1.png";
import simg2 from "../images/slide2.png";
import simg3 from "../images/slide3.png";
import simg4 from "../images/slide4.png";

import sImgS1 from "../images/service-single/img-1.jpg";
import sImgS2 from "../images/service-single/img-2.jpg";
import sImgS3 from "../images/service-single/img-3.jpg";
import sImgS4 from "../images/service-single/img-4.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg2,
    sImgS: sImgS1,
    sTitle: "Deep Learning\n& Evolutionary Algorithm",
    description:
      "딥러닝과 진화알고리즘을 결합하여 혁신적 자동 작곡 엔진을 이용합니다. EVOM 시스템은 실제 인간의 작곡 과정을 학습하여 완전히 새로운 작곡 아이디어를 제공합니다.",
    desc2:
      "It uses an innovative automatic composition engine that combines deep learning and evolutionary algorithms. The EVOM system learns from real human composition processes to provide completely new composition ideas.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-advisor",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg3,
    sImgS: sImgS2,
    sTitle: "User-based Interface",
    description:
      "MUSIA는 전문작곡가와 작곡입문자의 니즈를 파악하여 매주 새로운 알고리즘과 유저 인터페이스를 맞춤 제공합니다. 사용자의 니즈에 맞추어 자유로운 커스터마이징이 가능하도록 알고리즘을 구현하였습니다.",
    desc2:
      "MUSIA understands the needs of professional composers and beginners in composition and provides new algorithms and user interfaces tailored to their needs every week. An algorithm has been implemented to enable free customization according to the user's needs.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-team",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "4",
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: "Evolutional Composing",
    description:
      "자동 검사 시스템을 이용한 우수한 수준의 진화 기술은 사람이 작곡한 수준과 대응가능한 작곡을 보장해줍니다. 단일한 방식의 작곡이 아닌 피드백을 다시 학습한 알고리즘으로 지속적으로 작곡 기술이 향상됩니다.",
    desc2:
      "Excellent evolution technology using an automatic inspection system ensures compositions comparable to those of human compositions. Rather than a single method of composing, composition skills are continuously improved through an algorithm that re-learns feedback.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-tax",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "3",
    sImg: simg,
    sImgS: sImgS3,
    sTitle: "EVOM Engine",
    description:
      "화성학을 비롯한 음악이론에 대해 규칙 기반 전문가 시스템(Rule based Expert system)을 사용한 EVOM 엔진을 사용합니다. EVOM 엔진은 음악의 기본 구조를 생성하고, 특정 장르와 레퍼런스 음악의 특징 요소를 학습할 수 있는 딥러닝 알고리즘을 적용했습니다.",
    desc2:
      "We use the EVOM engine using a rule-based expert system for music theory, including harmony. The EVOM engine generates the basic structure of music and applies a deep learning algorithm that can learn the characteristic elements of specific genres and reference music.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-taxes",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];

export default Services;
