import React from "react";
import ContactForm from "../ContactFrom/ContactForm";

import { atom, useRecoilState } from "recoil";

const Contactpage = () => {
  const langState = atom({
    key: "langState", // unique ID (with respect to other atoms/selectors)
    default: navigator.language === "ko" ? "ko" : "en", // default value (aka initial value)
  });
  const [lang, setLang] = useRecoilState(langState);

  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row justify-content-center">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-place"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Address</h2>
                      {lang === "ko" && (
                        <p>
                          서울 서초구
                          <br />
                          반포대로 38 와라빌딩 5층
                        </p>
                      )}
                      {lang === "en" && (
                        <p>
                          5th floor, Wara Building
                          <br />
                          38 Banpo-daero, Seocho-gu, Seoul
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-email"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p>support@musiaplugin.com</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="wpo-contact-title">
              <h2>Have Any Question?</h2>
              <p>
                It is a long established fact that a reader will be distracted
                content of a page when looking.
              </p>
            </div>
            <div className="wpo-contact-form-area">
              <ContactForm />
            </div> */}
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.0176750252494!2d127.00934407650269!3d37.48390927206022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca11141cf2735%3A0x7972cb8332f2b35c!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDrsJjtj6zrjIDroZwgMzg!5e0!3m2!1sko!2skr!4v1693558811494!5m2!1sko!2skr"></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
