import pimg1 from "../images/project/img-1.jpg";
import pimg2 from "../images/project/img-2.jpg";
import pimg3 from "../images/project/img-3.jpg";
import pimg4 from "../images/project/img-31.jpg";
import pimg5 from "../images/project/img-4.jpg";
import pimg6 from "../images/project/img-5.jpg";
import pimg7 from "../images/project/img-6.jpg";
import pimg8 from "../images/project/img-7.jpg";
import pimg9 from "../images/project/img-8.jpg";
import pimg10 from "../images/project/1.png";
import pimg11 from "../images/project/2.png";
import pimg12 from "../images/project/3.png";
import pimg13 from "../images/project/4.png";

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/1.jpg";
import ps1img6 from "../images/project-single/2.jpg";
import ps1img7 from "../images/project-single/3.jpg";
import ps1img8 from "../images/project-single/5.jpg";
import ps1img9 from "../images/project-single/6.jpg";

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";

import prImg1 from "../images/product1.jpg";
import prImg2 from "../images/product2.jpg";
import prImg3 from "../images/product3.jpg";
import prImg4 from "../images/product4.jpg";
import prImg5 from "../images/product5.jpg";

const Projects = [
  {
    Id: "1",
    pImg: prImg1,
    ps1img: ps1img1,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Musia Radio",
    title2: "Musia Radio",
    subTitle: "사진/영상 분위기 맞춤형 AI 음악 라디오",
    subTitle2: "Photo/Video mood customized AI music radio",
  },
  {
    Id: "2",
    pImg: prImg2,
    ps1img: ps1img2,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "MUSIA Plugin",
    title2: "MUSIA Plugin",
    subTitle: "쉽고 빠른 작곡을 위한 MIDI 생성 프로그램",
    subTitle2: "MIDI creation program for easy and fast composition",
  },
  {
    Id: "3",
    pImg: prImg3,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "DoRap101",
    title2: "DoRap101",
    subTitle:
      "AI 비트에 맞춰 랩을 하고 소통을 할 수 있는 힙합 소셜 미디어 플랫폼",
    subTitle2:
      "A hip-hop social media platform where you can rap and communicate to AI beats.",
  },
  {
    Id: "4",
    pImg: prImg4,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "MUSIA 스트리밍 유튜브",
    title2: "MUSIA YouTube",
    subTitle: "총 재생수 200만 회 누적의 AI 음악 유튜브",
    subTitle2: "AI music YouTube with a total of 2 million views",
  },
  {
    Id: "5",
    pImg: prImg5,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "현대차 공동 연구",
    title2: "A joint study of Hyundai Motor Co",
    subTitle: "AI 음악 생성 기반의 다양한 연구 진행 중",
    subTitle2: "Various research based on AI music generation is in progress",
  },
];

export default Projects;
